import React, { Component } from 'react';
import { Alert, Button, Col, FormGroup } from 'reactstrap';

import { capitalizeFirstLetter } from '../../utils';
import { formValueSelector, Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import * as operatorActions from '../../actions/operatorActions';
import * as erpTypeActions from '../../actions/erpTypeActions';
import * as userActions from '../../actions/userActions';
import { required, email, passwordsMatch } from './Validations';
import { renderInputField } from '../../components/Field';

import XMAuditTable from '../../components/XMAuditTable';
import { getAuth, TotpMultiFactorGenerator, multiFactor, sendEmailVerification } from 'firebase/auth';
import QRCode from 'qrcode';

const selector = formValueSelector('operatorForm');

// STATE (STORE) NEED ELEMENTS
const mapStateToProps = (state) => ({
  config: state.config,
  operator: state.operator,
  erpType: state.erpType,
  dataCentre: state.dataCentre,
  password: selector(state, 'password'),
  selectedUser: state.user.selected,
  currentIdentityUser: state.currentIdentityUser,
});

// ACTIONS
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      lookupOperator: operatorActions.lookupOperator,
      cleanOperator: operatorActions.cleanOperator,
    },
    dispatch
  );
};

// SETUP REDUX FORM
@reduxForm({
  form: 'operatorForm',
  enableReinitialize: true,
})
// CONNECT TO REDUX
@connect(mapStateToProps, mapDispatchToProps)
class OperatorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataCentreSelected: null,
    };
    this.submit = this.submit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.totpSecret = null;
    this.success = null;
    this.onChangeDataCentre = this.onChangeDataCentre.bind(this);
  }

  componentDidMount() {
    const { erpType, dispatch } = this.props;
    if (!erpType.fetched) {
      dispatch(erpTypeActions.getAll());
    }
    this.setState({
      showVerfication: false,
    });
  }

  submit = (operator) => {
    const { toggle, dataCentre, selectedUser, dispatch } = this.props;

    // add dataCentre if dataCentre has been modified
    if (this.state.dataCentreSelected) {
      operator.dataCentre = {
        id: this.state.dataCentreSelected,
      };
    } else if (!operator.id) {
      operator.dataCentre = {
        id: dataCentre.selected.id,
      };
    }

    // just to make sure the dataCentre is set
    if (!operator.dataCentre) {
      operator.dataCentre = {
        id: dataCentre.selected.id ?? dataCentre.all[0].id,
      };
    }

    dispatch(operatorActions.save(operator)).then(() => {
      if (operator.id === selectedUser.id && operator.role !== selectedUser.role) {
        dispatch(userActions.logOut());
      }
      toggle();
    });
  };

  onCancel() {
    const { handleCancel, toggle } = this.props;
    this.setState({
      showVerfication: false,
    });
    handleCancel();
    toggle();
  }

  onChangeDataCentre(event, value) {
    this.setState({
      dataCentreSelected: value,
    });
  }

  activate2FA(operator) {
    const { dispatch } = this.props;
    if (getAuth().currentUser == null) {
      //dispatch(userActions.logOut());
      return;
    }

    multiFactor(getAuth().currentUser)
      .getSession()
      .then((multiFactorSession) => {
        TotpMultiFactorGenerator.generateSecret(multiFactorSession)
          .then((totpSecret) => {
            this.totpSecret = totpSecret;
            const totpUri = totpSecret.generateQrCodeUrl(getAuth().currentUser.email, 'CB Store Manager');
            QRCode.toCanvas(canvas, totpUri)
              .then((res) => {
                this.setState({
                  showVerfication: true,
                });
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((e) => {
            if (e.code == 'auth/unverified-email') {
              sendEmailVerification(getAuth().currentUser).then(() => {});
              dispatch({
                type: 'OPERATOR_SAVE_ERROR',
                payload: 'Need to verify email first before enrolling second factors.',
              });
            } else if (e.code == 'auth/maximum-second-factor-count-exceeded') {
              dispatch({
                type: 'OPERATOR_SAVE_ERROR',
                payload: '2FA has been enrolled for this account.',
              });
            } else if (e.code == 'auth/requires-recent-login') {
              dispatch({
                type: 'OPERATOR_SAVE_ERROR',
                payload: 'Re-authentication required to enrolled for 2FA.',
              });
            } else {
              dispatch({
                type: 'OPERATOR_SAVE_ERROR',
                payload: e.message,
              });
            }
          });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  verify2FA(operator) {
    const { dispatch } = this.props;
    if (otp.value == '') {
      dispatch({
        type: 'OPERATOR_SAVE_ERROR',
        payload: 'Verification Code is required.',
      });
      return;
    }
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(this.totpSecret, otp.value);
    multiFactor(getAuth().currentUser)
      .enroll(multiFactorAssertion, getAuth().currentUser.displayName)
      .then((res) => {
        this.success = 'Successfully Enrolled for 2FA';
        dispatch({
          type: 'OPERATOR_SAVE_SUCCESS',
        });
        const { handleCancel, toggle } = this.props;
        this.setState({
          showVerfication: false,
        });
        handleCancel();
        toggle();
      })
      .catch((e) => {
        var errMsg = e.message;
        if (e.code == 'auth/invalid-verification-code') {
          errMsg = '2FA Verification Code is incorrect or expired';
        }
        dispatch({
          type: 'OPERATOR_SAVE_ERROR',
          payload: errMsg,
        });
      });
  }

  render() {
    const { config, operator, dataCentre, handleSubmit, submitting, isEdit, profileSetting } = this.props;
    const userSelected = JSON.parse(localStorage.getItem('userSelected'));
    return (
      <div>
        <div>
          <form onSubmit={handleSubmit(this.submit)} className="form-horizontal" autoComplete="new-password">
            {operator.error && <Alert color="danger">{operator.error}</Alert>}
            <FormGroup row>
              <Col md="3">
                <label>Name</label>
              </Col>
              <Col md="9">
                <Field
                  name="name"
                  component={renderInputField}
                  type="text"
                  className="form-control"
                  validate={required}
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="off"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <label>Username</label>
              </Col>
              <Col md="9">
                <Field
                  name="username"
                  component={renderInputField}
                  type="text"
                  className="form-control"
                  validate={[email, required]}
                  disabled={isEdit}
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="off"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <label>Password</label>
              </Col>
              <Col md="9">
                <Field
                  name="password"
                  component={renderInputField}
                  type="password"
                  className="form-control"
                  validate={!isEdit ? [required] : []}
                  autoComplete="sdfdsfds"
                  autoCorrect="off"
                  spellCheck="off"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <label htmlFor="password2">Confirm Password</label>
              </Col>
              <Col md="9">
                <Field
                  name="password2"
                  component={renderInputField}
                  type="password"
                  className="form-control"
                  validate={!isEdit ? [required, passwordsMatch] : [passwordsMatch]}
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="off"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <label htmlFor="phone">Phone</label>
              </Col>
              <Col md="9">
                <Field name="phone" component={renderInputField} type="text" className="form-control" />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <label htmlFor="dataCentre">Default Data Center</label>
              </Col>
              <Col md="9">
                <Field
                  name="dataCentre.id"
                  component="select"
                  className="form-control"
                  onChange={this.onChangeDataCentre}
                  disabled={userSelected.role === 'PARTNER'}
                >
                  {userSelected.role !== 'PARTNER' ? (
                    dataCentre.all.map((dc) => {
                      return (
                        <option key={dc.id} value={dc.id}>
                          {dc.name}
                        </option>
                      );
                    })
                  ) : (
                    <option value={dataCentre.selected.id}>{dataCentre.selected.name}</option>
                  )}
                </Field>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <label htmlFor="role">Role</label>
              </Col>
              <Col md="9">
                <Field
                  name="role"
                  component="select"
                  className="form-control"
                  disabled={userSelected.role === 'PARTNER'}
                >
                  {userSelected.role !== 'PARTNER' ? (
                    config.enums &&
                    config.enums['userRole'].map((role, key) => {
                      return (
                        <option key={key} value={role}>
                          {capitalizeFirstLetter(role)}
                        </option>
                      );
                    })
                  ) : (
                    <option value="PARTNER">Partner</option>
                  )}
                </Field>
              </Col>
            </FormGroup>
            <div className="float-end">
              <Button type="button" color="secondary" className="me-2" onClick={() => this.onCancel()}>
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={submitting}>
                Save
              </Button>
            </div>
          </form>
        </div>
        {profileSetting ? (
          <div style={{ paddingTop: '3rem' }}>
            <FormGroup row>
              <Col md="3">{this.state.showVerfication ? <label>Verification Code</label> : null}</Col>
              <Col md="3">{this.state.showVerfication ? <input id="otp" /> : null}</Col>
              <Col md="6">
                <div>
                  {this.state.showVerfication ? (
                    <Button type="button" color="primary" className="me-2" onClick={() => this.verify2FA(this)}>
                      Verify Code
                    </Button>
                  ) : null}
                  {!this.state.showVerfication ? (
                    <Button type="button" color="primary" className="me-2" onClick={() => this.activate2FA(this)}>
                      Activate 2FA
                    </Button>
                  ) : null}
                </div>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3"></Col>
              <Col md="3">
                <canvas id="canvas"></canvas>
              </Col>
            </FormGroup>
          </div>
        ) : null}
        <XMAuditTable show={isEdit} audit={operator.one} />
      </div>
    );
  }
}

export default OperatorForm;
