// NODE_ENV based on build.sh
const PRODUCTION = 'production';
const STAGING = 'staging';
const INTEGRATION = 'integration';
const QUALITYASSURANCE = 'qualityassurance';
const DEVELOPMENT = 'development';

export const isProduction = () => process.env.NODE_ENV === PRODUCTION;
export const isStaging = () => process.env.NODE_ENV === STAGING;
export const isDevelopment = () => process.env.NODE_ENV === DEVELOPMENT
  || process.env.NODE_ENV === INTEGRATION
  || process.env.NODE_ENV === QUALITYASSURANCE;
